import { Button, Typography, Box } from '@mui/material';
import logo from '../../ mascotyou_ai_logo.svg';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const overview =
  'At MascotYou AI we are crowdsourcing wellness. This survey is designed to create a comprehensive baseline of your personality through four distinct dimensions.';

function Home() {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/survey');
  };

  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{ padding: '20px' }}
        />

        {/* Add spacing between elements using sx */}
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          MascotYou AI Personality Survey
        </Typography>

        <Typography sx={{ marginBottom: 4 }}>{overview}</Typography>

        <Button
          variant="contained"
          onClick={handleStart}
          sx={{ padding: '10px 20px' }} // Adds padding inside the button
        >
          Take Survey
        </Button>
      </header>
    </div>
  );
}

export default Home;
