import React from 'react';
import styled from 'styled-components';

const SpotifyButton = styled.button`
  width: 100%;
  background-color: #1db954; // Spotify green
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1ed760;
  }
`;

const SpotifyLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const SpotifyLoginButton = ({ onClick }) => {
  return (
    <SpotifyButton onClick={onClick}>
      <SpotifyLogo
        src="https://upload.wikimedia.org/wikipedia/commons/8/84/Spotify_icon.svg"
        alt="Spotify Logo"
      />
      Login with Spotify
    </SpotifyButton>
  );
};

export default SpotifyLoginButton;
