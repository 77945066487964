import React from 'react';
import styled from 'styled-components';

const AppleMusicButton = styled.button`
  background-color: #000000; // Apple Music black
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #333333;
  }
`;

const AppleMusicLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

const AppleMusicLoginButton = ({ onClick }) => {
  return (
    <AppleMusicButton onClick={onClick}>
      <AppleMusicLogo
        src="https://upload.wikimedia.org/wikipedia/commons/5/5f/Apple_Music_icon.svg"
        alt="Apple Music Logo"
      />
      Login with Apple Music
    </AppleMusicButton>
  );
};

export default AppleMusicLoginButton;
