import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IPIPSection from '../../components/SurveySections/IPIP/IPIP';
import ConclusionSection from '../../components/SurveySections/Conclusion/ConclusionSection';
import SocialBasedInsightSection from '../../components/SurveySections/SocialBasedInsight/SocialBasedInsightSection';
import ContentBasedInsightSection from '../../components/SurveySections/ContentBaseInsight/ContentBasedInsightSection';

import './transitionStyles.css'; // Include transition styles

const API_HOST = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const SurveyNew = () => {
  const [currentSection, setCurrentSection] = useState(0); // Track the current section
  const [allAnswered, setAllAnswered] = useState(false); // Track if all questions are answered for enabling/disabling the button
  const [UUID, setUUID] = useState();
  // Proceed to the next section
  const handleNextSection = () => {
    setCurrentSection(currentSection + 1);
    setAllAnswered(false); // Reset the "allAnswered" state when transitioning to a new section
  };

  // This function will be passed down to each section to update the "allAnswered" state
  const handleAllAnswered = (isAnswered) => {
    setAllAnswered(isAnswered);
  };

  useEffect(() => {
    const uploadResults = async (body) => {
      try {
        const response = await axios.post(`${API_HOST}/api/results/add`, body, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Success:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (currentSection === 3) {
      // Upload results to API
      const id = uuidv4();
      setUUID(id);

      const body = {
        id: id,
        ipipResults: sessionStorage.getItem('IPIPSectionResults'),
        contentResults: sessionStorage.getItem('ContentBasedInsightResults'),
        waitlistResults: sessionStorage.getItem('SocialBasedInsightResults'),
      };

      uploadResults(body);
    }
  }, [currentSection]);

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <TransitionGroup>
        <CSSTransition key={currentSection} timeout={500} classNames="slide">
          <div>
            {/* {currentSection === 0 && (
              <PersonalThoughtSection onAllAnswered={handleAllAnswered} />
            )} */}
            {currentSection === 0 && (
              <IPIPSection onAllAnswered={handleAllAnswered} />
            )}
            {currentSection === 1 && (
              <ContentBasedInsightSection onAllAnswered={handleAllAnswered} />
            )}
            {currentSection === 2 && (
              <SocialBasedInsightSection onAllAnswered={handleAllAnswered} />
            )}
            {currentSection === 3 && UUID && <ConclusionSection id={UUID} />}
          </div>
        </CSSTransition>
      </TransitionGroup>

      {currentSection < 3 && (
        <div>
          {/* {allAnswered && <span>Next Section</span>} */}
          <IconButton
            sx={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              backgroundColor: '#1976d2',
              color: 'white',
              '&:hover': {
                backgroundColor: '#115293',
              },
              '&.Mui-disabled': {
                backgroundColor: '#ccc',
                color: '#fff',
              },
            }}
            disabled={!allAnswered} // Button is enabled only when all questions are answered
            color="primary"
            onClick={handleNextSection}
          >
            <ArrowForwardIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default SurveyNew;
