import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const PersonalityBarChart = ({ scores }) => {
  const labels = Object.keys(scores);
  const values = Object.values(scores);

  return (
    <BarChart
      width={1000}
      height={500}
      series={[{ data: values }]}
      xAxis={[{ data: labels, scaleType: 'band' }]}
    />
  );
};

export default PersonalityBarChart;
