import './IPIP.css';
import BFFMQuestions from './questions';
import testAnswers from './testAnswers';
import { useState, useEffect } from 'react';
import {
  LinearProgress,
  Box,
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

const options = [
  { option: 'Very Inaccurate', value: 1 },
  { option: 'Moderately Inaccurate', value: 2 },
  { option: 'Neither Accurate Nor Inaccurate', value: 3 },
  { option: 'Moderately Accurate', value: 4 },
  { option: 'Very Accurate', value: 5 },
];

const initTraitScores = {
  Openness: 0,
  Conscientiousness: 0,
  Extraversion: 0,
  Agreeableness: 0,
  Neurotiscim: 0,
};

const getValueByOption = (optionName) => {
  const foundOption = options.find((option) => option.option === optionName);
  return foundOption ? foundOption.value : null;
};

const calculatePercentageScores = (rawScores, maxScorePerTrait) => {
  const percentageScores = {};

  for (const trait in rawScores) {
    if (rawScores.hasOwnProperty(trait)) {
      const rawScore = rawScores[trait];
      percentageScores[trait] = rawScore / maxScorePerTrait;
    }
  }

  return percentageScores;
};

const title = '1-D: IPIP Questionnaire-Based Insight';
const overview =
  'MascotYou AI uses the IPIP (International Personality Item Pool) questionnaire, a standardized tool based on the Big Five Personality framework. This dimension assesses personality traits like openness, conscientiousness, extraversion, agreeableness, and emotional stability.';
const benefit =
  'The IPIP results give a structured, scientifically validated snapshot of your personality traits. It ensures that your subjective self-perception is balanced with an objective, reliable evaluation of core traits.';

const IPIP = ({ onAllAnswered }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState(Array(BFFMQuestions.length).fill(''));
  const [traitScores, setTraitScores] = useState(initTraitScores);
  const [finishedSection, setFinishedSection] = useState(false);
  const progress = ((currentQuestion + 1) / BFFMQuestions.length) * 100;

  useEffect(() => {
    const allAnswered = progress >= 99.9; // Check if all options are selected
    onAllAnswered(allAnswered); // Notify the parent
    
    if (allAnswered) {
      const traits = calculatePercentageScores(traitScores, 50);
      sessionStorage.setItem('IPIPSectionResults', JSON.stringify(traits));
      setFinishedSection(true);
    }
  }, [progress, onAllAnswered]);
  // const navigate = useNavigate();
  // const location = useLocation();

  // const { songs, service } = location.state;

  // Function to update a specific trait score
  const updateTraitScore = (traitId, newScore) => {
    const traitScore = traitScores[traitId];
    setTraitScores((prevScores) => ({
      ...prevScores,
      [traitId]: traitScore + newScore,
    }));
  };

  const handleOptionChange = (event) => {
    const newAnswers = [...answers];
    const answer = event.target.value;
    newAnswers[currentQuestion] = answer;
    setAnswers(newAnswers);
  };

  const handleNext = () => {
    if (currentQuestion < BFFMQuestions.length - 1) {
      // Get trait current question is associated with
      const trait = BFFMQuestions[currentQuestion].type;

      // Calculate the raw score of the answer to the question
      const selectedOption = answers[currentQuestion];
      const answerMath = BFFMQuestions[currentQuestion].math;
      const value = getValueByOption(selectedOption);
      const rawScore = answerMath === '+' ? value : 5 - (value - 1);

      updateTraitScore(trait, rawScore);

      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestion <= BFFMQuestions.length - 1) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleSkipToEnd = () => {
    const newAnswers = [];
    const tempTraitScores = { ...initTraitScores };

    for (let i = 0; i < BFFMQuestions.length; i++) {
      const answer = testAnswers[i].option;
      const trait = BFFMQuestions[i].type;
      // Calculate the raw score of the answer to the question
      const selectedOption = answer;
      const optionValue = getValueByOption(selectedOption);

      const answerMath = BFFMQuestions[i].math;
      const rawScore = answerMath === '+' ? optionValue : 5 - (optionValue - 1);
      tempTraitScores[trait] = (tempTraitScores[trait] || 0) + rawScore;
      newAnswers.push(answer);
    }
    setTraitScores(tempTraitScores);
    setAnswers(newAnswers);
    setCurrentQuestion(BFFMQuestions.length - 1);
  };

  return (
    <Box
      className="container"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
      <Box
        className="content"
        sx={{
          width: '75%',
          height: '75%',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          '&::-webkit-scrollbar': {
            width: '12px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
            border: '3px solid #f1f1f1',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>

        {/* Overview Section */}
        <div>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Overview
          </Typography>
          <Typography
            variant="body1"
            style={{ color: '#6b6b6b', fontWeight: 'normal' }}
            paragraph
          >
            {overview}
          </Typography>
        </div>

        {/* Benefit Section */}
        <div>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Benefit
          </Typography>
          <Typography
            variant="body1"
            style={{ color: '#6b6b6b', fontWeight: 'normal' }}
            paragraph
          >
            {benefit}
          </Typography>
        </div>

        {/* Progress Bar */}
        <Box sx={{ width: '100%' }}>
          {
            finishedSection ? (
              <Typography>Section Finished!</Typography>
            ) : (
              <LinearProgress variant="determinate" value={progress} />
            )
          }
        </Box>

        {/* Current Question */}
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            sx={{ fontSize: 'larger', fontWeight: 'bold', color: 'black' }}
          >
            {BFFMQuestions[currentQuestion]['question']}
          </FormLabel>
          <RadioGroup
            name={`question-${currentQuestion}`}
            value={answers[currentQuestion] || ''}
            onChange={handleOptionChange}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
          >
            {options.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option.option}
                control={<Radio />}
                label={option.option}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {/* Navigation Buttons */}
        <div className="btn-group">
          {currentQuestion < BFFMQuestions.length - 1 && (
            <Button
              className="next-btn"
              onClick={handleNext}
              disabled={!answers[currentQuestion]}
              variant="contained"
            >
              Next Question
            </Button>
          )}

          {currentQuestion > 0 && (
            <Button
              className="prev-btn"
              onClick={handlePrev}
              variant="contained"
              sx={{ marginTop: '0.5rem' }}
            >
              Previous Question
            </Button>
          )}

          {currentQuestion < BFFMQuestions.length - 1 &&
            process.env.NODE_ENV === 'development' && (
              <Button onClick={handleSkipToEnd}>Skip to end (dev)</Button>
            )}
        </div>
      </Box>
    </Box>
  );
};

export default IPIP;
