import './ContentBasedInsightSection.css';
import React, { useState, useEffect } from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { authorize, fetchRecentlyPlayed } from '@services/AppleAuth';
import AppleMusicLoginButton from '@components/Buttons/AppleMusicButton';
import SpotifyButton from '@components/Buttons/SpotifyButton';
// import TestData from './test_data.json'
import Stack from '@mui/material/Stack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const title = '2-D: Digital Content-Based Insight';
const overview =
  'MascotYou AI analyzes your digital content (e.g., music, posts, and other media) to detect mood patterns and personal interests. This creates a real-time assessment of your personality based on the content you engage with.';
const benefit =
  'By analyzing digital content, MascotYou AI uncovers aspects of your personality that are shaped by your daily habits and interactions. This dimension reflects dynamic changes in your preferences, making your profile more adaptive and current.';

const API_HOST = process.env.REACT_APP_API_URL || 'https://xfcn324wbi.execute-api.us-east-1.amazonaws.com/Prod';
const automaticUpload = true;

const MusicService = Object.freeze({
  Apple: 'Apple',
  Spotify: 'Spotify',
});

const ContentBasedInsightSection = ({ onAllAnswered }) => {
  const [loading, setLoading] = useState(false);
  const [musicService, setMusicService] = useState();
  const [recentSongs, setRecentSongs] = useState();

  const handleAppleMusicLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_HOST}/api/token`);
      const body = await response.json();
      const dToken = body.token;
      await authorize(dToken);

      const appleMusicSongs = await fetchRecentlyPlayed();

      setRecentSongs(appleMusicSongs);
      setMusicService(MusicService.Apple);
    } catch (error) {
      console.error('Error during Apple Music login:', error);
      toast.error('Apple Music login failed');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (musicService && recentSongs?.length > 0) {
      onAllAnswered(true);
      sessionStorage.setItem(
        'ContentBasedInsightResults',
        JSON.stringify(recentSongs)
      );
    }
  }, [musicService, onAllAnswered, recentSongs]);

  return (
    <Box
      className="container"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
      <ToastContainer />
      <Box
        className="content"
        sx={{
          width: '75%',
          height: '75%',
          backgroundColor: 'white',
          padding: '16px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gap: '12px',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
            border: '3px solid #f1f1f1',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: '8px' }}>
          {title}
        </Typography>

        {/* Overview Section */}
        <div>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: '4px' }}
          >
            Overview
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#6b6b6b',
              fontWeight: 'normal',
              marginBottom: '12px',
            }}
          >
            {overview}
          </Typography>
        </div>

        {/* Benefit Section */}
        <div>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: '4px' }}
          >
            Benefit
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#6b6b6b',
              fontWeight: 'normal',
              marginBottom: '12px',
            }}
          >
            {benefit}
          </Typography>
        </div>

        {/* Apple Music Login Button */}

        {loading ? (
          <CircularProgress />
        ) : musicService === undefined && automaticUpload === true ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Stack
              spacing={2}
              direction="column"
              alignItems="center"
              sx={{ maxWidth: '25%' }}
            >
              <AppleMusicLoginButton
                onClick={handleAppleMusicLogin}
                sx={{ width: '50%' }}
              />
              <SpotifyButton sx={{ width: '50%' }} />
            </Stack>
          </Box>
        ) : (
          <p>Login Successful!</p>
        )}
      </Box>
    </Box>
  );
};

export default ContentBasedInsightSection;
