import { Typography } from '@mui/material';
import logo from '../../../ mascotyou_ai_logo.svg';

const overview =
  'MascotYou AI’s creates a highly detailed, multi-dimensional view of your personality, ensuring that every interaction with the platform is tailored to who you are and who you’re becoming. This foundation enhances your overall experience, from mental health insights to social connections and personal growth strategies.';

function ConclusionSection({ id }) {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        {/* Add spacing between elements using sx */}
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Thank you!
        </Typography>

        <Typography
          sx={{
            paddingX: 4,
            maxWidth: '75%',
            margin: '0 auto',
            marginBottom: 4,
          }}
        >
          {overview}
        </Typography>

        <Typography
          fontWeight="bold"
          sx={{
            paddingX: 4,
            maxWidth: '75%',
            margin: '0 auto',
            marginBottom: 4,
          }}
        >
          Results ID: {id}
        </Typography>
      </header>
    </div>
  );
}

export default ConclusionSection;
