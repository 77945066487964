let musicKitInstance = null;

// Initialize MusicKit
function initializeMusicKit(developerToken) {
  if (!window.MusicKit) {
    throw new Error(
      'MusicKit is not available on the window object. Make sure it is loaded correctly.'
    );
  }

  if (!musicKitInstance) {
    // Fetch the developer token from your backend

    musicKitInstance = window.MusicKit.configure({
      developerToken: developerToken, // Replace with your actual Apple Music developer token
      app: {
        name: 'MascotYou AI',
        build: '1.0.0',
      },
    });
  }
  return musicKitInstance;
}

// Authorize the user to access their Apple Music account
async function authorize(devToken) {
  try {
    if (!musicKitInstance) {
      initializeMusicKit(devToken);
    }
    const musicUserToken = await musicKitInstance.authorize();
    return musicUserToken;
  } catch (error) {
    console.error('Authorization failed:', error);
    throw error;
  }
}

// Fetch the user's recently played songs
async function fetchRecentlyPlayed() {
  try {
    const response = await musicKitInstance.api.library.songs();
    const songs = response.map((song) => ({
      artistName: song.attributes.artistName,
      trackName: song.attributes.name,
    }));

    return songs;
  } catch (error) {
    console.error('Error fetching recently played songs:', error);
    throw error;
  }
}

export { initializeMusicKit, authorize, fetchRecentlyPlayed };
