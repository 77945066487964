import './SocialBasedInsightSection.css';
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@mui/material';

const title = '3-D: Social Circle-Based Insight';
const overview =
  'Feedback from your social circle—friends, peers, or coworkers—gives anonymous insight into how others perceive your personality. This external perspective reveals how your traits are interpreted in social contexts.';
const benefit =
  'Social feedback helps you understand the alignment or gaps between how you see yourself and how others see you. This contributes to your self-awareness and understanding of your social interactions.';

const SocialBasedInsightSection = ({ onAllAnswered }) => {
  const [didAnswer, setDidAnswer] = useState(false);
  const [willJoinWaitlist, setWillJoinWaitlist] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isStudent, setIsStudent] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  // Regular expression to validate email format
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex pattern
    return re.test(String(email).toLowerCase());
  };

  const handleWaitlistQuestion = (answer) => {
    setDidAnswer(true);
    setWillJoinWaitlist(answer);
  };

  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleStudentChange = (answer) => setIsStudent(answer);
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    setEmail(inputEmail);
    if (validateEmail(inputEmail)) {
      setEmailError(''); // Clear error if email is valid
    } else {
      setEmailError('Invalid email format'); // Set error if email is invalid
    }
  };

  useEffect(() => {
    const allQuestionsAnswered =
      didAnswer &&
      willJoinWaitlist === 'yes' &&
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      isStudent.length > 0 &&
      emailError.length === 0;
    if (
      (didAnswer && willJoinWaitlist === 'no') ||
      (didAnswer && willJoinWaitlist === 'already_joined') ||
      allQuestionsAnswered
    ) {
      onAllAnswered(true);
      const results = {
        willJoinWaitlist: willJoinWaitlist,
        firstName: firstName,
        lastName: lastName,
        email: email,
        isStudent: isStudent,
      };
      sessionStorage.setItem(
        'SocialBasedInsightResults',
        JSON.stringify(results)
      );
    } else {
      onAllAnswered(false);
    }
  }, [
    didAnswer,
    willJoinWaitlist,
    firstName,
    lastName,
    email,
    isStudent,
    emailError,
    onAllAnswered,
  ]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: '75%',
          height: '75%',
          backgroundColor: 'white',
          padding: '16px', // Reduced padding
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px', // Reduce spacing between sections
          justifyContent: 'flex-start', // Align items at the top
          '&::-webkit-scrollbar': {
            width: '8px', // Reduced scrollbar size
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
            border: '3px solid #f1f1f1',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>

        {/* Overview Section */}
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: '8px' }}
          >
            Overview
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: '#6b6b6b', marginBottom: '12px' }}
          >
            {overview}
          </Typography>
        </Box>

        {/* Benefit Section */}
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, marginBottom: '8px' }}
          >
            Benefit
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: '#6b6b6b', marginBottom: '12px' }}
          >
            {benefit}
          </Typography>
        </Box>

        {/* Waitlist Question */}
        <FormControl
          component="fieldset"
          margin="normal"
          sx={{ marginBottom: '12px' }}
        >
          <FormLabel component="legend">
            Do you want to join the waitlist?
          </FormLabel>
          <RadioGroup
            name="waitlist-question"
            value={willJoinWaitlist || ''}
            onChange={(event) => handleWaitlistQuestion(event.target.value)}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
            <FormControlLabel
              value="already_joined"
              control={<Radio />}
              label="Already Joined"
            />
          </RadioGroup>
        </FormControl>

        {/* Personal Information Section */}
        {didAnswer && willJoinWaitlist === 'yes' && (
          <Box>
            <FormControl component="fieldset" sx={{ marginBottom: '16px' }}>
              <FormLabel component="legend">Are you a student?</FormLabel>
              <RadioGroup
                name="is-student-question"
                value={isStudent || ''}
                onChange={(event) => handleStudentChange(event.target.value)}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>

            {/* Name and Email Fields */}
            {isStudent.length > 0 && (
              <FormControl fullWidth>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="First Name"
                      variant="outlined"
                      fullWidth
                      value={firstName}
                      onChange={handleFirstNameChange}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      value={lastName}
                      onChange={handleLastNameChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label={isStudent === 'yes' ? 'College Email' : 'Email'}
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={handleEmailChange}
                      error={!!emailError}
                      helperText={emailError}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SocialBasedInsightSection;
