const BFFMQuestions = [
  { question: 'Am the life of the party.', type: 'Openness', math: '+' },
  {
    question: 'Feel little concern for others.',
    type: 'Conscientiousness',
    math: '-',
  },
  { question: 'Am always prepared.', type: 'Extraversion', math: '+' },
  { question: 'Get stressed out easily.', type: 'Agreeableness', math: '-' },
  { question: 'Have a rich vocabulary.', type: 'Neurotiscim', math: '+' },
  { question: "Don't talk a lot.", type: 'Openness', math: '-' },
  {
    question: 'Am interested in people.',
    type: 'Conscientiousness',
    math: '+',
  },
  { question: 'Leave my belongings around.', type: 'Extraversion', math: '-' },
  {
    question: 'Am relaxed most of the time.',
    type: 'Agreeableness',
    math: '+',
  },
  {
    question: 'Have difficulty understanding abstract ideas.',
    type: 'Neurotiscim',
    math: '-',
  },
  { question: 'Feel comfortable around people.', type: 'Openness', math: '+' },
  { question: 'Insult people.', type: 'Conscientiousness', math: '-' },
  { question: 'Pay attention to details.', type: 'Extraversion', math: '+' },
  { question: 'Worry about things.', type: 'Agreeableness', math: '-' },
  { question: 'Have a vivid imagination.', type: 'Neurotiscim', math: '+' },
  { question: 'Keep in the background.', type: 'Openness', math: '-' },
  {
    question: "Sympathize with others' feelings.",
    type: 'Conscientiousness',
    math: '+',
  },
  { question: 'Make a mess of things.', type: 'Extraversion', math: '-' },
  { question: 'Seldom feel blue.', type: 'Agreeableness', math: '+' },
  {
    question: 'Am not interested in abstract ideas.',
    type: 'Neurotiscim',
    math: '-',
  },
  { question: 'Start conversations.', type: 'Openness', math: '+' },
  {
    question: "Am not interested in other people's problems.",
    type: 'Conscientiousness',
    math: '-',
  },
  { question: 'Get chores done right away.', type: 'Extraversion', math: '+' },
  { question: 'Am easily disturbed.', type: 'Agreeableness', math: '-' },
  { question: 'Have excellent ideas.', type: 'Neurotiscim', math: '+' },
  { question: 'Have little to say.', type: 'Openness', math: '-' },
  { question: 'Have a soft heart.', type: 'Conscientiousness', math: '+' },
  {
    question: 'Often forget to put things back in their proper place.',
    type: 'Extraversion',
    math: '-',
  },
  { question: 'Get upset easily.', type: 'Agreeableness', math: '-' },
  {
    question: 'Do not have a good imagination.',
    type: 'Neurotiscim',
    math: '-',
  },
  {
    question: 'Talk to a lot of different people at parties.',
    type: 'Openness',
    math: '+',
  },
  {
    question: 'Am not really interested in others.',
    type: 'Conscientiousness',
    math: '-',
  },
  { question: 'Like order.', type: 'Extraversion', math: '+' },
  { question: 'Change my mood a lot.', type: 'Agreeableness', math: '-' },
  {
    question: 'Am quick to understand things.',
    type: 'Neurotiscim',
    math: '+',
  },
  {
    question: "Don't like to draw attention to myself.",
    type: 'Openness',
    math: '-',
  },
  {
    question: 'Take time out for others.',
    type: 'Conscientiousness',
    math: '+',
  },
  { question: 'Shirk my duties.', type: 'Extraversion', math: '-' },
  { question: 'Have frequent mood swings.', type: 'Agreeableness', math: '-' },
  { question: 'Use difficult words.', type: 'Neurotiscim', math: '+' },
  {
    question: "Don't mind being the center of attention.",
    type: 'Openness',
    math: '+',
  },
  { question: "Feel others' emotions.", type: 'Conscientiousness', math: '+' },
  { question: 'Follow a schedule.', type: 'Extraversion', math: '+' },
  { question: 'Get irritated easily.', type: 'Agreeableness', math: '-' },
  {
    question: 'Spend time reflecting on things.',
    type: 'Neurotiscim',
    math: '+',
  },
  { question: 'Am quiet around strangers.', type: 'Openness', math: '-' },
  {
    question: 'Make people feel at ease.',
    type: 'Conscientiousness',
    math: '+',
  },
  { question: 'Am exacting in my work.', type: 'Extraversion', math: '+' },
  { question: 'Often feel blue.', type: 'Agreeableness', math: '-' },
  { question: 'Am full of ideas.', type: 'Neurotiscim', math: '+' },
];

export default BFFMQuestions;
