import React from 'react';
import { useLocation } from 'react-router-dom';
import PersonalityBarChart from '@components/PersonalityBarChart';

import './Results.css';

const Results = () => {
  const location = useLocation();
  const { scores, id } = location.state;

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <h2>Here are your personality insight results!</h2>
      <div className="bar-chart">
        <PersonalityBarChart scores={scores}></PersonalityBarChart>
      </div>
      <div>
        <h4>Results code: {id}</h4>
      </div>
    </div>
  );
};

export default Results;
